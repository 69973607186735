.main-content {
  margin-top: 10%;
  margin-bottom: 15%;
  padding-left: 5%;
  text-align: left;
}

.main-content p {
  font-size: 1.2em;
  width: 50vw;
}

.main-content strong {
  font-size: 1.5em;
}

.contact-button {
  cursor: pointer;
  padding: 10px 20px;
  font-weight: 900;
  color: #fff;
  background-color: #1d3557;
  border: none;
  border-radius: 7%;
}

.main-content button:hover {
  opacity: 0.88;
}

@media (max-width: 700px) {
  .main-content {
    width: 90vw;
    margin-top: 70px;
    padding-left: 10px;
  }

  .main-content p {
    width: 81%;
  }
}
