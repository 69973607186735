.skills-container {
  margin-top: 10%;
  padding-left: 5%;
  text-align: left;
}

.skills-container h2 {
  margin-bottom: 45px;
}

.proficient-icons {
  display: "flex";
  flex-wrap: wrap;
  gap: "30px";
}

.proficient-icons svg,
.proficient-icons img {
  height: 45px;
  fill: #fff;
  margin-bottom: 7px;
}

.test-box {
  background-color: rgba(33, 37, 41);
  border-radius: 6%;
  padding: 15px;
  height: 100px;
  width: 100px;
  text-align: center;
}

.test-box span {
  margin-top: 10px;
  color: #fff;
}

@media only screen and (max-width: 700px) {
  .skills-container {
    padding: 1px;
  }

  .skills-container h2 {
    /* padding-left: 20px; */
    margin-top: 40px;
  }

  .proficient-icons {
    justify-content: space-between;
  }
}
