.project-group {
  margin-top: 200px;
  padding-left: 5%;
}

.card-container {
  width: 600px;
}

.card-img {
  height: 220px;
}

.card-text {
  padding: 20px;
}

.tech-icons {
  height: 35px;
}

.card-icons {
  display: flex;
  gap: 15px;
}

.projects-header {
  text-align: left;
  margin-bottom: 40px;
  align-items: center;
}

.project-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 50px;
  justify-content: flex-start;
}

@media only screen and (max-width: 1420px) {
  .card-img {
    display: none;
  }

  .card-text {
    padding: 15px;
  }

  .card-btn {
    padding: 3px;
  }

  .card-container {
    width: 45%;
  }
}

@media only screen and (max-width: 950px) {
  .card-icons {
    gap: 15px;
  }

  .project-group {
    margin-top: 100px;
    text-align: center;
    padding-left: 5%;
  }

  .card-container {
    width: 99%;
  }

  .card-text {
    padding: 5px;
  }

  .project-cards {
    justify-content: center;
  }
}

@media only screen and (max-width: 700px) {
  .project-group {
    padding-left: 0px;
  }
}
